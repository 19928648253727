import { Routes } from "@angular/router"


export const RouterRoutes : Routes = [

    {
        path: 'listing',
        loadComponent : ()=> import("./pages/routes-listing/routes-listing.component").then((m)=>m.RoutesListingComponent)
    },
    {
        path : '',
        redirectTo : 'listing',
        pathMatch : 'full'
    },
    {
        path : 'add',
        loadComponent : ()=> import("./pages/add-routes/add-routes.component").then((m)=>m.AddRoutesComponent)
    },
    {
        path : 'edit/:id',
        loadComponent : ()=> import("./pages/add-routes/add-routes.component").then((m)=>m.AddRoutesComponent)
    }
]