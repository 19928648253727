import { Routes } from "@angular/router";

export const  storeRoutes : Routes = [
    {
        path : 'add',
        loadComponent : () => import('./pages/add-store/add-store.component').then(m => m.AddStoreComponent)
    },
    {
        path : 'storeList',
        loadComponent : () => import('./pages/store-list/store-list.component').then(m => m.StoreListComponent)
    },
    {
        path : 'add/:id',
        loadComponent : () => import('./pages/add-store/add-store.component').then(m => m.AddStoreComponent)
    },
    {
        path : '',
        pathMatch : "full",
        redirectTo : 'storeList'
    }
]

