import {Routes} from "@angular/router"

export const   userRouter : Routes = [
    {
       path : 'userList',
       loadComponent : ()=> import('./pages/user-list/user-list.component').then(m=> m.UserListComponent)
    },
    {
        path : '',
        pathMatch : "full",
        redirectTo : 'userList'
     },
     {
      path : 'checkInDetail/:id',
      loadComponent : ()=> import('./pages/check-in-details/check-in-details.component').then(m => m.CheckInDetailsComponent)
     },
     {
      path : 'add',
      loadComponent : ()=> import('./pages/add-user/add-user.component').then(m => m.AddUserComponent)
     },
     {
      path : 'add/:id',
      loadComponent : ()=> import('./pages/add-user/add-user.component').then(m => m.AddUserComponent)
      
     },
     {
      path :'employeeDetails/:id',
      loadComponent :() => import ('./pages/employee-details/employee-details.component').then(m => m.EmployeeDetailsComponent)
     }
   //   {
   //    path : 'edit/:id',
   //    loadComponent : ()=> import('./pages/user-edit/user-edit.component').then(m => m.UserEditComponent)
   //   }
]