import { Routes } from "@angular/router";
export const rightsRouter: Routes = [
    {
        path: 'rights',
        loadComponent:() => import ('./pages/add-rights/add-rights.component').then(m=>m.AddRightsComponent)
       
      
      },
      {
        path: '',
        pathMatch: "full",
        redirectTo: 'rights'
      },

]