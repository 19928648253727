import { Routes } from "@angular/router"
// import { Router } from 'express';

export const documentRouter: Routes = [
  {
    path: 'documents',
    loadComponent: () => import ('./pages/documents-listing/documents-listing.component').then(m=>m.DocumentsListingComponent)
  
  },
  {
    path: '',
    pathMatch: "full",
    redirectTo: 'documents'
  },

  {
    path : 'addDocs',
    loadComponent:() => import('./pages/add-dorments/add-dorments.component').then(m=>m.AddDormentsComponent)
    
   },

   {
    path : 'addDocs/:id',
    loadComponent:() => import('./pages/add-dorments/add-dorments.component').then(m=>m.AddDormentsComponent)
    
   },

   {
    path:"docsDetails/:id",
    loadComponent:() => import('./pages/documents-details/documents-details.component').then(m=>m.DocumentsDetailsComponent)
   }


]