import { CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable, inject } from "@angular/core";
import { UtilitySerice } from '../shared/services/utility.service';
import { DataTransferService } from '../shared/services/data-transfer.service';


@Injectable({
  providedIn: 'root'
})
class roleGuardDetails {
  router = inject(Router)
  permission: any;
  returnData !: boolean;
  // _utilityService = inject(UtilitySerice)
  _dataTransferService = inject(DataTransferService)
  constructor() {

  }
  canActivate(route: ActivatedRouteSnapshot, next: RouterStateSnapshot) {
    // console.log(route.data, "hello route check")
    const roleData: any = localStorage.getItem('survey_admin_role');
    // console.log("yes comes in guardsss in role",roleData)
    let data = {
      role: roleData
    }

    if (roleData == 1) {
      // console.log("sidebar data role 1")
      this.returnData = true;
    } else {
      let res: any = this._dataTransferService.getpermissions(data).subscribe((res: any) => {
        console.log(res, "any check data")
        if (res.data && res.data.permission) {
          this.permission = res.data.permission;



          const routeTitle = route.data['title']
          // console.log(routeTitle, "route title check data", res?.data?.permission[route?.data["title"]])

          if (this.permission[route?.data["title"]] != 1) {
            return this.returnData = true;
            // resolve(true);

          } else {
            // console.log("yes eithin else data")
            this.router.navigate(["/"]);
            return this.returnData = false;
          }
        } else {
          this.router.navigate(["/"]);
          return this.returnData = false;
        }




      })
    }



    return this.returnData;
    // if(res)
    // {
    //   console.log(res,"hello response data")

    // }
    // this._dataTransferService.getpermissions().subscribe((res: any) => {
    //   if (res.result.role == 1) resolve(true);
    //   else if (res?.result?.adminRole[route?.data["title"]]?.view == true) {
    //     resolve(true);
    //   } else {
    //     this.router.navigate(["/"]);
    //     resolve(false);
    //   }
    // });



    // return true
    // if (this._utilityService.getAuthToken()) {
    //     return true;
    //     // return this.tokenVerify();
    // }


    // return this.navigate();




  }



}

// export const roleGuard: CanActivateFn = (route:ActivatedRouteSnapshot, state:RouterStateSnapshot) => {
//   console.log(route,"hello route",route.data)
//   return true;
//   // return inject(roleGuardDetails).canActivate(route , state);
// };

export const roleGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(roleGuardDetails).canActivate(route, state); // Ensure this returns a valid guard result
};

// export const canActivateTeam: CanActivateFn = (
//   route: ActivatedRouteSnapshot,
//   state: RouterStateSnapshot,
// ) => {
//   return inject(PermissionsService).canActivate(route , state);
// };
