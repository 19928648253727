import { ApplicationConfig , importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';

import { routes } from './app.routes';
// import { provideClientHydration } from '@angular/platform-browser';
import { TokenInterceptor } from './interceptor/token.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes),
    //  provideClientHydration() , 
     importProvidersFrom([BrowserAnimationsModule]) , 
  provideHttpClient(
    withFetch(),
    withInterceptorsFromDi(),
  ),
  {provide : HTTP_INTERCEPTORS , useClass : TokenInterceptor , multi : true}
 ]
};


