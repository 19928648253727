import { CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable, inject } from "@angular/core";
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { DataTransferService } from '../shared/services/data-transfer.service';
import { Action , EMPLOYEE_ROLE } from '../constant/constant';

@Injectable({
  providedIn: 'root'
})
class RoleGuardDetails {
  router = inject(Router);
  _dataTransferService = inject(DataTransferService);
  ACTION_NAME = Action;

  // List of all possible routes with required titles
  allRoutes = [
    { path: 'user', title: 'employee' },
    { path: 'blockROute', title: 'blockRoute' },
    { path: 'checkInList', title: 'checkIn' },
    { path: 'store', title: 'store' },
    { path: 'route', title: 'routeMaster' },
    { path: 'settings', title: 'settings' },
    { path: 'reports', title: 'reports' },
    { path: 'documents', title: 'masterDocuments' },
    { path: 'rights', title: 'rights' },
    { path: 'assignment', title: 'assignments' },
    { path: 'approvals', title: 'approvals' }
  ];

  canActivate(route: ActivatedRouteSnapshot, next: RouterStateSnapshot): Observable<boolean> {
    const roleData = localStorage.getItem('survey_admin_role') || 1
    
    if (+roleData === EMPLOYEE_ROLE.ADMIN) {
      return of(true);
    }

    return this._dataTransferService.getpermissions({ role: roleData }).pipe(
      map((res: any) => {
        const permission = res?.data?.permission;
        const routeTitle = route.data['title'];

        if (permission && permission[routeTitle] !== this.ACTION_NAME.NONE) {
          return true;
        } else {
          // Find the first route where the user has permission
          const firstAllowedRoute = this.allRoutes.find(r => permission[r.title] !== this.ACTION_NAME.NONE);
          
          if (firstAllowedRoute) {
            this.router.navigate([`/${firstAllowedRoute.path}`]);
          } else {
            // If no allowed route is found, redirect to a generic "No Access" page
            this.router.navigate(['/no-access']);
          }

          return false;
        }
      }),
      catchError(() => {
        this.router.navigate(['/no-access']);
        return of(false);
      })
    );
  }
}

export const roleGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(RoleGuardDetails).canActivate(route, state);
};
