import {Routes} from "@angular/router"
// import { roleGuard } from './guards/role.guard';
import { roleGuard } from "../../guards/role.guard"
export const clusterRoutes : Routes = [
   
    {
        path : 'clusterList',
        loadComponent : ()=> import("./pages/cluster-listing/cluster-listing.component").then((c)=>c.ClusterListingComponent),
        // canActivate: [roleGuard],
        // data:{
        //     title:"blockRoute"
        // }
    },
    {
        path : '',
        redirectTo : 'clusterList',
        pathMatch : 'full'
    },
    {
        path : 'add-cluster',
        loadComponent : ()=> import("./pages/add-cluster/add-cluster.component").then((c)=>c.AddClusterComponent),
        // canActivate: [roleGuard],
        // data:{
        //     title:"blockRoute"
        // }
    },
    {
        path : 'edit/:id',
        loadComponent : ()=> import("./pages/add-cluster/add-cluster.component").then((c)=>c.AddClusterComponent),
        // canActivate: [roleGuard],
        // data:{
        //     title:"blockRoute"
        // }
    }
        
]